import React from 'react';
import Header from './header';

import { URL } from '../variables';
import { connect } from 'react-redux';
import { mudarUser, logout, mudarProcesso, mudarSocket } from '../actions/AppActions';
import { Redirect, Link } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import moment from 'moment-timezone';
import DrawAnnotations from './DrawAnotations';
import ModalErrors from './ModalErrors';
import html2canvas from 'html2canvas';

class RedacaoCorrigida extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            msg: '',
            loading_screen: true,
            redirect: false,
            path: '/',
            sala: {},
            correcao: {},
            redacoes: [],
            redacao_original: {},
            c1: 0,
            c2: 0,
            c3: 0,
            c4: 0,
            c5: 0,
            domain: '',

            c3_pontos: 0,
            nota_zero: false,
            fuga_tema: false,
            texto_nao_articulado: false,
            identificacao_candidato: false,
            em_branco: false,
            texto_insuficiente: false,
            comentario: '',
            loading_save: false,
            msg_erro: '',
            show_warning_finish: false,
            show_error: false,
            show_success: false,
            annotations: [],
            processo: {},

            criterios_notas_zeradas: [
                {
                    name: 'Fugiu ao tema e/ou gênero propostos',
                    value: false
                },
                {
                    name: 'Apresentou nome, rubrica, assinatura, sinal, iniciais ou marcas que permitam a identificação do candidato',
                    value: false
                },
                {
                    name: 'Está em branco',
                    value: false
                },
                {
                    name: 'Apresentou textos sob forma não articulada verbalmente (apenas com desenhos, números e/ou palavras soltas)',
                    value: false
                },
                {
                    name: 'Foi escrita em outra língua que não seja a solicitada (portuguesa ou espanhola)',
                    value: false
                },
                {
                    name: 'Foi composta predominantemente por cópia de trechos do(s) texto(s) de apoio',
                    value: false
                },
                {
                    name: 'Consistiu em texto contendo plágio ou cópias de textos da internet',
                    value: false
                },
                {
                    name: 'Apresentou formas propositais de anulação, como impropérios, trechos jocosos ou a recusa explícita em cumprir o tema proposto',
                    value: false
                },
                {
                    name: 'Apresentou menos 15 (quinze) linhas (sem contar o título)',
                    value: false
                }
            ],
            selects_in_text: [],
            selects_in_title: [],
            show_modal_aspect: false,
            new_anotation: null,
            annotation_edit: null
        }
        this.container = React.createRef();
        // this.videoRef=null;
    }


    componentDidMount() {
        this.get_correcao(this.props.match.params.id);

    }

    componentWillUnmount() {

    }

    select_disabled() {
        if (this.state.nota_zero || this.state.fuga_tema || this.state.texto_nao_articulado || this.state.identificacao_candidato
            || this.state.em_branco || this.state.texto_insuficiente) {
            return true;
        }
        else {
            return false;
        }
    }

    async get_correcao(id) {
        this.setState({ loading_screen: true });
        fetch(`${URL}api/corretor/get_correcao/${id}/${this.props.match.params.correcao_id}`, {
            method: 'GET',
            headers: {
                Accept: 'application/json',

                'Authorization': `Bearer ${this.props.token}`,
                // 'Content-Type': 'application/json',
            }
        }).then(async (responseLog) => {
            try {
                let resp = await responseLog.json();
                console.log(resp);
                if (resp.message == "Unauthenticated.") {
                    localStorage.removeItem('token');
                    // this.setState({redirect:true,path:'/'});
                    this.props.logout();
                }
                if (resp.errors != null || resp.error != null) {
                    let errors = Object.values(resp.errors);
                    let erro = '';
                    for (let i = 0; i < errors.length; i++) {
                        if (i != errors.length - 1)
                            erro += errors[i] + '\n';
                        else
                            erro += errors[i];
                    }
                    console.log(erro);

                    // this.props.mudarLoadingHome(false);

                }
                else {
                    let c3_pontos=0;
                    for(let i=0;i<resp.correcao.anotations.length;i++){
                        c3_pontos+=resp.correcao.anotations[i].aspects.filter((item, id) => item.value == true).length;
                    }
                    this.setState({ domain:resp.domain, 
                        comentario:resp.correcao.comentario,
                        c3_pontos:c3_pontos, annotations: resp.correcao.anotations, c1: resp.correcao.c1, c2: resp.correcao.c2, c3: resp.correcao.c3,c4: resp.correcao.c4,c5: resp.correcao.c5, loading_screen: false, correcao: resp.correcao, criterios_notas_zeradas: resp.correcao.criterios, processo: resp.processo }, () => {
                        this.tamanho_annotation();
                    });

                }




            } catch (err) {
                console.log(err);
                // this.props.mudarLoadingHome(false);


            }

        })
            .catch((err) => {
                console.log(err);
                // this.props.mudarLoadingHome(false);

            });
    }
    lines() {
        let lines = []
        // style={{ lineHeight: '38px' }}
        for (let i = 1; i <= 1000; i = i + 1) {
            lines.push(<p key={i} className="count_line" style={{ borderBottom: '1px solid lightgrey', lineHeight: '32px' }}
            >{i}</p>)
        }

        return lines;

    }



    is_overlapping(x1, x2, y1, y2) {
        if ((x1 < x2 && y1 < y2) || (x1 > y2 && y1 > y2)) {
            return false;
        }
        return true;
        //         let xmin = Math.min(x1,x2)
        //   , xmax = Math.max(x1,x2)
        //   , ymin = Math.min(y1,y2)
        //   , ymax = Math.max(y1,y2);

        //         for (let i = xmin; i < xmax; ++i)
        //             if (ymin <= i && i <= ymax)
        //                 return true;

        //         return false;

    }


    tamanho_annotation() {
        if(this.props.faculdade.criterio.numero_criterios==5 || this.props.faculdade.criterio.numero_criterios==6 ||  this.props.faculdade.criterio.numero_criterios==4){
            return;
        }
        let count = 0;
        let c3 = 0;
        for (let i = 0; i < this.state.annotations.length; i++) {
            count += this.state.annotations[i].aspects.filter((item, id) => item.value == true).length;
        }
        if (count > 26) {
            c3 = 0;
        }
        else if (count <= 26 && count >= 20) {
            c3 = 1
        }
        else if (count <= 19 && count >= 13) {
            c3 = 2
        }
        else if (count <= 12 && count >= 7) {
            c3 = 3
        }
        else if (count <= 6) {
            c3 = 4;
        }

        this.setState({ c3_pontos: count, c3 });
        // return count;
    }

    render() {
        let index = 0;
        return (
            <div ref={this.container}>

                {this.state.loading_screen == false && <div className="row">
                    {this.state.redirect == true && <Redirect to={this.state.path} />}


                    {/* <div className="col-12">
                        <div className="card">
                            <div className="card-body">
                                <h4 className="mt-0 header-title" style={{ fontSize: '1.5rem', color: 'black' }}>{this.props.faculdade.name}</h4>
                                <p style={{ fontSize: '1rem', color: 'black' }}>Nome do Fiscal: {this.props.user.name}</p>

                                <h4 style={{ color: 'black' }}>Comunicado Geral</h4>


                                <div className="d-flex" style={{ marginBottom: '0.5rem', marginTop: '1rem' }}>
                                    <textarea id="msg_comunicado" placeholder="Escreva um comunicado Geral para enviar aos candidatos" className="form-control" style={{ resize: 'none', height: '100px' }} />
                                    <button className="btn btn-primary" id="enviar_comunicado">
                                        Enviar
                                        </button>
                                </div>

                            </div>
                        </div>
                    </div> */}
                    <div className="col-12">
                        <div className="card">
                            <div className="card-body" >
                                <div className="row" style={{ padding: '1.25rem' }}>

                                    <div className="col-12" style={{ marginBottom: '1rem' }}>
                                        <div style={{ padding: '1.25rem', position: 'absolute', right: 0, width: '150px' }}>
                                            <Link onClick={() => {

                                            }} to={'/list/' + this.state.processo.id+'?tab=1'} className="btn btn-secondary btn-outline btn-circle m-r-5" style={{ width: '105px' }}><i
                                                className="mdi mdi-reply"
                                                style={{ fontSize: '15px', color: 'white', fontStyle: 'normal', paddingRight: '1rem' }}>
                                                    Voltar</i></Link>


                                        </div>
                                        <h4 style={{ color: 'black' }}>Redação</h4>
                                        <p className="m-b-20" style={{ color: 'black', whiteSpace: 'pre-line' }}>Candidato {this.state.correcao.candidato.numero_inscricao}</p>
                                        <div style={{color:'black'}}>
                                        <p><b>Título: </b>{this.state.correcao.texto.redacao.name}</p>
                                        <b>Enunciado:</b>
                                        <div className="jodit-wysiwyg" dangerouslySetInnerHTML={{__html:this.state.correcao.texto.redacao.enunciado }}>
                                        </div>
                                            
                                        </div>
                                        <hr />
                                        <div >
                                        <div className='hidden_scrollbar d-block d-lg-flex' style={{  justifyContent: 'center', alignItems: 'center',overflowX: 'scroll' } }>

                                                {/* <div id="redacao" style={{ width: 750 }}> */}
                                                <div id="redacao" style={{ width: this.state.correcao.texto.file == null ? 750 : '1050px',  height:this.state.correcao.texto.file == null ? 'auto' :  '1550px' }}>

                                                    <p className="m-b-20" style={{ color: 'black', whiteSpace: 'pre-line', textAlign: 'center', fontSize: '1.5rem', fontWeight: 'bold' }}>Candidato #{this.state.correcao.candidato.numero_inscricao == null ? this.state.correcao.candidato_id : this.state.correcao.candidato.numero_inscricao}
                                                    </p>
                                                    {/* <button className="btn btn-info" onMouseDown={this.getSelText.bind(this)}>Selecionar</button> */}
                                                    <div style={{ marginTop: '2rem' }}>
                                                    {this.state.correcao.texto.file == null && <div className="row">
                                                            <div className="col-12 d-flex justify-content-center">
                                                                <div name="titulo" maxLength={60} autoComplete={'off'} className="form-control" style={{ marginLeft: '3rem', borderTop: '0px', borderRight: '0px', borderLeft: '0px', paddingLeft: 0, paddingRight: 0, width: '678px', textAlign: 'center', fontSize: '18px', fontFamily: "'Courier New', Courier, monospace", fontWeight: 'bold', backgroundColor: 'white' }} dangerouslySetInnerHTML={{ __html: this.state.correcao.texto.titulo == null ? '' : this.state.correcao.texto.titulo }}></div>
                                                            </div>
                                                        </div>}
                                                        <br />
                                                        <div className={(this.state.correcao.texto.file != null ? '' : 'row  d-flex') + " container_scroll justify-content-center"}
                                                            style={{ marginBottom: '1.25rem', display: 'block', paddingLeft: '2rem', overflow: 'hidden', flexWrap: 'inherit', position: 'relative' }}>


<div style={{ width: this.state.correcao.texto.file == null ? '710px' : 1010, paddingLeft:this.state.correcao.texto.file != null ?0: '2rem', zIndex: 3, position: 'relative' }}>
                                                                {this.state.correcao.texto.file != null && <img
                                                                    style={{ width: 1010, height: 1424 }}
                                                                    id='image_id'
                                                                    src={this.state.correcao.url}
                                                                />}
                                                               {this.state.correcao.texto.file == null &&  <div

                                                                    ref={this.textoRef} name="texto" autoComplete="off" className="lines" style={{ zIndex: 1, width: '678px', whiteSpace: 'pre-wrap',height:Math.ceil((1000/30)*this.state.correcao.texto.redacao.number_lines) }} dangerouslySetInnerHTML={{ __html: 
                                                                    //"   " + 
                                                                    "\t" + 
                                                                    (this.state.correcao.texto.texto == null ? '' : this.state.correcao.texto.texto) }} maxLength={3000} rows={30} role="textbox">
                                                                </div>}
                                                                {/* <div
                                                      
                                                      name="texto1" autoComplete="off" className="lines" style={{zIndex:2, position:'absolute',top:0, width: '678px', whiteSpace: 'pre-wrap' }} dangerouslySetInnerHTML={{ __html: "\t" + (this.state.correcao.texto.texto == null ? '' : this.state.correcao.texto.texto) }} maxLength={3000} rows={30} role="textbox">
                                                 </div> */}
                                                                <DrawAnnotations
                                                                height={this.state.correcao.texto.file == null ? (Math.ceil((1000/30)*this.state.correcao.texto.redacao.number_lines)+30) : 1424}
                                                                width={this.state.correcao.texto.file == null ? 710 : 1010}
                                                                redacao={this.state.correcao.texto}

                                                                    annotation_edit={this.state.annotation_edit}
                                                                    showEditAnnotation={(e) => {

                                                                    }}
                                                                    annotations={this.state.annotations} MouseEnter={(id, value) => {


                                                                    }} new_anotation={this.state.new_anotation} newAnnotation={(e) => {

                                                                    }} />
                                                                <ModalErrors
                                                                    removeAnotation={(annotation) => {

                                                                    }}
                                                                    annotation_edit={this.state.annotation_edit} show_modal_aspect={this.state.show_modal_aspect} setState={(e) => {
                                                                    }} onSave={(data) => {

                                                                    }} />
                                                            </div>
                                                            {this.state.correcao.texto.file == null && <div style={{
                                                                // height: '1000px', 
                                                                height:Math.ceil((1000/30)*this.state.correcao.texto.redacao.number_lines),
                                                                overflow: 'hidden', position: 'absolute',
                                                                width: '710px',
                                                                zIndex: 0, paddingTop: '0.5rem'
                                                            }} ref={this.lineCount}
                                                            >
                                                                {this.lines()}

                                                            </div>}


                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div>

                                                <div>
                                                    <p style={{ fontWeight: 'bold', fontSize: '1rem' }}>Marcações</p>
                                                </div>
                                                <div className="table-rep-plugin" style={{ marginTop: '2rem' }}>
                                                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                        <table id="tech-companies-1" className="table table-striped">
                                                            <thead>
                                                                <tr style={{ textAlign: 'center' }}>

                                                                    <th>Número</th>


                                                                    <th>Aspectos</th>
                                                                    <th>Comentário</th>
                                                                    {/* <th>Ações</th> */}

                                                                    {/* <th>NOTA OBTIDA</th> */}

                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {this.state.annotations.map((item, id) => <tr key={id} style={{ textAlign: 'center' }}>
                                                                    <th>{id + 1})</th>
                                                                    <th style={{ whiteSpace: 'pre-line' }}>{item.aspects.map((item2, id2) => {
                                                                        if (item2.value == true) {
                                                                            return item2.name + "\n";
                                                                        }
                                                                    })}</th>
                                                                    <th>{item.comentario == null || item.comentario.trim().length == 0 ? '-' : item.comentario}</th>
                                                                    {/* <td>
                                                                            <button onClick={() => {
                                                                                let annotation_edit = { ...item };
                                                                                this.setState({ show_modal_aspect: true, annotation_edit: annotation_edit });
                                                                            }} className="btn btn-info btn-outline btn-circle m-r-5"
                                                                                data-toggle="tooltip"
                                                                            ><i className="ti-pencil-alt"></i></button>
                                                                            <button onClick={() => {
                                                                                let annotations = this.state.annotations.filter((item2, id2) => item2.id != item.id);
                                                                                this.setState({ annotations },()=>{
                                                                                    this.tamanho_annotation();
                                                                                });
                                                                            }} className="btn btn-danger btn-outline btn-circle m-r-5"
                                                                                data-toggle="tooltip"
                                                                            ><i className="ti-trash"></i></button>
                                                                        </td> */}

                                                                </tr>)}
                                                            </tbody>
                                                        </table>
                                                        {this.state.annotations.length == 0 && <div className="row w-100">
                                                            <div className="col-12">
                                                                <p style={{ textAlign: 'center' }}>Nenhuma marcação registrada</p>

                                                            </div>
                                                        </div>}
                                                    </div>
                                                </div>
                                                <br />
                                                <div>
                                                    <p style={{ fontWeight: 'bold', fontSize: '1rem' }}>Critérios</p>
                                                </div>
                                                {this.props.faculdade.criterio.numero_criterios==3 && <div className="table-rep-plugin" style={{ marginTop: '2rem' }}>
                                                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                        <table id="tech-companies-1" className="table table-striped">
                                                            <thead>
                                                                <tr style={{ textAlign: 'center' }}>

                                                                    <th>CÓD.</th>


                                                                    <th>CRITÉRIOS</th>
                                                                    <th>NOTA MÁXIMA</th>
                                                                    <th colSpan="2">NOTA OBTIDA</th>



                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>Conteúdo</th>
                                                                    <th>O texto deve abordar integralmente o tema proposto. Avaliar-se-ão a capacidade argumentativa, a criatividade do autor, bem como a originalidade do ponto de vista</th>
                                                                    <th>4.0</th>
                                                                    <th>
                                                                        {this.state.c1 == 0 && 'NA - Não atende – 0,0'}
                                                                        {this.state.c1 == 1 && 'S - Suficiente – 1,0'}
                                                                        {this.state.c1 == 2 && 'B - Bom – 2,0'}
                                                                        {this.state.c1 == 3 && 'MB - Muito bom – 3,0'}
                                                                        {this.state.c1 == 4 && 'OT - Ótimo – 4,00'}


                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>Estrutura</th>
                                                                    <th>Avaliar-se-á a capacidade de o autor do texto elaborar um texto dissertativo, distribuindo-o adequadamente nas partes que o compõe. Além disso, deve-se atentar também para a construção dos parágrafos: é esperado que o texto apresente os seguintes elementos: introdução adequada ao tema/posicionamento, apresentação da ideia a ser discutida e a tese a ser defendida.</th>
                                                                    <th>2.0</th>
                                                                    <th>
                                                                        {this.state.c2 == 0 && 'NA - Não atende – 0,0'}
                                                                        {this.state.c2 == 1 && 'B - Bom – 1,0'}
                                                                        {this.state.c2 == 2 && 'MB - Muito bom – 2,0'}

                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>Expressão</th>
                                                                    <th>Avaliar-se-á os seguintes aspectos: adequação vocabular, pontuação, concordância e regência verbal e nominal, ortografia oficial vigente, acentuação gráfica, sintaxe e morfossintaxe.</th>
                                                                    <th>4.0</th>
                                                                    <th>
                                                                        {this.state.c3_pontos > 26 && <span>0,0 ponto – mais que 26 erros</span>}
                                                                        {this.state.c3_pontos <= 26 && this.state.c3_pontos >= 20 && <span>1,0 pontos – de 20 a 26 erros</span>}
                                                                        {this.state.c3_pontos <= 19 && this.state.c3_pontos >= 13 && <span>2,0 pontos – de 13 a 19 erros</span>}
                                                                        {this.state.c3_pontos <= 12 && this.state.c3_pontos >= 7 && <span>3,0 pontos – de 7 a 12 erros</span>}
                                                                        {this.state.c3_pontos <= 6 && <span>4,0 pontos – no máximo 6 erros</span>}

                                                                        {/* <select value={this.state.c3} className="custom-select" id="cameras" onChange={(e) => {
                                                                                this.setState({ c3: e.target.value });
                                                                            }}>
                                                                                <option value={''}>Selecione uma opção</option>

                                                                                <option value={0}>0,0 ponto – mais que 26 erros</option>
                                                                                <option value={1}>1,0 pontos – de 20 a 26 erros</option>
                                                                                <option value={2}>2,0 pontos – de 13 a 19 erros</option>
                                                                                <option value={3}>3,0 pontos – de 7 a 12 erros</option>
                                                                                <option value={4}>4,0 pontos – no máximo 6 erros</option>

                                                                            </select> */}
                                                                    </th>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div className="row">

                                                            {this.state.criterios_notas_zeradas.map((item, id) => (
                                                                <div key={id} className="col-12" >
                                                                    <div className="form-check form-check-inline">
                                                                        <input readOnly disabled className="form-check-input"  type="checkbox" checked={item.value} />
                                                                        <label className="form-check-label" style={{
                                                                            fontWeight: 'normal',
                                                                            color: 'black',
                                                                            fontSize: '13px'
                                                                        }}>{item.name}</label>
                                                                    </div>
                                                                    <br />
                                                                    <br />


                                                                </div>
                                                            ))}

                                                        </div>
                                                    </div>
                                                </div>}


                                                {this.props.faculdade.criterio.numero_criterios==4 && <div className="table-rep-plugin" style={{marginTop:'2rem'}}>
                                                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                        <table id="tech-companies-1" className="table table-striped">
                                                            <thead>
                                                                <tr style={{ textAlign: 'center' }}>

                                                                    <th>CÓD.</th>
                                                                    <th>CRITÉRIOS</th>
                                                                    <th>NOTA MÁXIMA</th>
                                                                    <th>NOTA OBTIDA</th>



                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C1</th>
                                                                    <th>Atendimento e desenvolvimento do tema / Informatividade e argumentação.</th>
                                                                    <th>9.0</th>
                                                                    <th>
                                                                        {parseFloat(this.state.c1).toFixed(2)}
                                                                        
                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                <th>C2</th>
                                                                    <th>Coesão intra e entre parágrafos (referencial e sequencial, diversificada e recorrente) / Coerência (progressão, articulação, não-contradição).</th>
                                                                    <th>5.0</th>
                                                                    <th>
                                                                    {parseFloat(this.state.c2).toFixed(2)}

                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                <th>C3</th>
                                                                    <th>Atendimento à estrutura textual proposta (organização do texto dissertativo-argumentativo e dos parágrafos).</th>
                                                                    <th>4.0</th>
                                                                    <th>
                                                                    {parseFloat(this.state.c3).toFixed(2)}

                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                <th>C4</th>
                                                                    <th>Modalidade gramatical: pontuação, grafia (inclusive legibilidade), concordância, regência e colocação pronominal.</th>
                                                                    <th>2.0</th>
                                                                    <th>
                                                                    {parseFloat(this.state.c4).toFixed(2)}

                                                                    </th>
                                                                </tr>


                                                            </tbody>
                                                        </table>
                                                        <div className="row">

                                                                {this.state.criterios_notas_zeradas.map((item, id) => (
                                                                    <div key={id} className="col-12 col-sm-6 col-md-4" >
                                                                        <div className="form-check form-check-inline">
                                                                            <input readOnly disabled className="form-check-input"  type="checkbox" checked={item.value} />
                                                                            <label className="form-check-label" style={{
                                                                                fontWeight: 'normal',
                                                                                color: 'black',
                                                                                fontSize: '13px'
                                                                            }}>{item.name}</label>
                                                                        </div>
                                                                        <br />
                                                                        <br />


                                                                    </div>
                                                                ))}

                                                            </div>

                                                       
                                                    </div>
                                                </div>}


                                                {this.props.faculdade!=null && this.props.faculdade.criterio.numero_criterios==5 && <div className="table-rep-plugin" style={{marginTop:'2rem'}}>
                                                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                        <table id="tech-companies-1" className="table table-striped">
                                                            <thead>
                                                                <tr style={{ textAlign: 'center' }}>

                                                                    <th>CÓD.</th>
                                                                    <th>CRITÉRIOS</th>
                                                                    <th>NOTA MÁXIMA</th>
                                                                    <th>NOTA OBTIDA</th>



                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C1</th>
                                                                    <th>Adequação ao tema e/ou à tipologia textual.</th>
                                                                    <th>5.0</th>
                                                                    <th>
                                                                        {parseFloat(this.state.c1).toFixed(2)}
                                                                        
                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C2</th>
                                                                    <th>Estrutura textual, construção pertinente de introdução, desenvolvimento e conclusão.</th>
                                                                    <th>5.0</th>
                                                                    <th>
                                                                    {parseFloat(this.state.c2).toFixed(2)}

                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C3</th>
                                                                    <th>Pertinência e riqueza de argumentos.</th>
                                                                    <th>5.0</th>
                                                                    <th>
                                                                    {parseFloat(this.state.c3).toFixed(2)}

                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C4</th>
                                                                    <th>Relação lógica entre as ideias, objetividade, ordenação e clareza das ideias.</th>
                                                                    <th>5.0</th>
                                                                    <th>
                                                                    {parseFloat(this.state.c4).toFixed(2)}

                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C5</th>
                                                                    <th>Expressão (domínio da norma culta da Língua Portuguesa e das estruturas da Língua)</th>
                                                                    <th>5.0</th>
                                                                    <th>
                                                                    {parseFloat(this.state.c5).toFixed(2)}

                                                                    </th>
                                                                </tr>


                                                            </tbody>
                                                        </table>
                                                        <div className="row">

                                                                {this.state.criterios_notas_zeradas.map((item, id) => (
                                                                    <div key={id} className="col-12 col-sm-6 col-md-4" >
                                                                        <div className="form-check form-check-inline">
                                                                            <input readOnly disabled className="form-check-input"  type="checkbox" checked={item.value} />
                                                                            <label className="form-check-label" style={{
                                                                                fontWeight: 'normal',
                                                                                color: 'black',
                                                                                fontSize: '13px'
                                                                            }}>{item.name}</label>
                                                                        </div>
                                                                        <br />
                                                                        <br />


                                                                    </div>
                                                                ))}

                                                            </div>

                                                       
                                                    </div>
                                                </div>}



                                                {this.props.faculdade!=null && this.props.faculdade.criterio.numero_criterios==6 && <div className="table-rep-plugin" style={{marginTop:'2rem'}}>
                                                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                        <table id="tech-companies-1" className="table table-striped">
                                                            <thead>
                                                                <tr style={{ textAlign: 'center' }}>

                                                                    <th>CÓD.</th>
                                                                    <th>CRITÉRIOS</th>
                                                                    <th>NOTA MÁXIMA</th>
                                                                    <th>NOTA OBTIDA</th>



                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C1</th>
                                                                    <th>Adequação ao tema e/ou à tipologia textual.</th>
                                                                    <th>10.0</th>
                                                                    <th>
                                                                        {parseFloat(this.state.c1).toFixed(2)}
                                                                        
                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C2</th>
                                                                    <th>Estrutura textual, construção pertinente de introdução, desenvolvimento e conclusão.</th>
                                                                    <th>10.0</th>
                                                                    <th>
                                                                    {parseFloat(this.state.c2).toFixed(2)}

                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C3</th>
                                                                    <th>Pertinência e riqueza de argumentos.</th>
                                                                    <th>10.0</th>
                                                                    <th>
                                                                    {parseFloat(this.state.c3).toFixed(2)}

                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C4</th>
                                                                    <th>Relação lógica entre as ideias, objetividade, ordenação e clareza das ideias.</th>
                                                                    <th>10.0</th>
                                                                    <th>
                                                                    {parseFloat(this.state.c4).toFixed(2)}

                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C5</th>
                                                                    <th>Expressão (domínio da norma culta da Língua Portuguesa e das estruturas da Língua)</th>
                                                                    <th>10.0</th>
                                                                    <th>
                                                                    {parseFloat(this.state.c5).toFixed(2)}

                                                                    </th>
                                                                </tr>


                                                            </tbody>
                                                        </table>
                                                        <div className="row">

                                                                {this.state.criterios_notas_zeradas.map((item, id) => (
                                                                    <div key={id} className="col-12 col-sm-6 col-md-4" >
                                                                        <div className="form-check form-check-inline">
                                                                            <input readOnly disabled className="form-check-input"  type="checkbox" checked={item.value} />
                                                                            <label className="form-check-label" style={{
                                                                                fontWeight: 'normal',
                                                                                color: 'black',
                                                                                fontSize: '13px'
                                                                            }}>{item.name}</label>
                                                                        </div>
                                                                        <br />
                                                                        <br />


                                                                    </div>
                                                                ))}

                                                            </div>

                                                       
                                                    </div>
                                                </div>}
                                                {/* <div className="table-rep-plugin" style={{marginTop:'2rem'}}>
                                                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                        <table id="tech-companies-1" className="table table-striped">
                                                            <thead>
                                                                <tr style={{ textAlign: 'center' }}>

                                                                    <th>CÓD.</th>
                                                                    <th>CRITÉRIOS</th>
                                                                    <th>NOTA MÁXIMA</th>
                                                                    <th>NOTA OBTIDA</th>



                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C1</th>
                                                                    <th>Adequação ao tema e/ou à tipologia textual.</th>
                                                                    <th>5.0</th>
                                                                    <th>
                                                                        <select value={this.state.c1} className="custom-select" id="cameras" onChange={(e) => {
                                                                            this.setState({ c1: e.target.value });
                                                                        }}>
                                                                            <option value={0}>0.0</option>
                                                                            <option value={0.5}>0.5</option>
                                                                            <option value={1}>1.0</option>
                                                                            <option value={1.5}>1.5</option>
                                                                            <option value={2}>2.0</option>
                                                                            <option value={2.5}>2.5</option>
                                                                            <option value={3}>3.0</option>
                                                                            <option value={3.5}>3.5</option>
                                                                            <option value={4}>4.0</option>
                                                                            <option value={4.5}>4.5</option>
                                                                            <option value={5}>5.0</option>

                                                                        </select>
                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C2</th>
                                                                    <th>Estrutura textual, construção pertinente de introdução, desenvolvimento e conclusão.</th>
                                                                    <th>5.0</th>
                                                                    <th>
                                                                        <select value={this.state.c2} className="custom-select" id="cameras" onChange={(e) => {
                                                                            this.setState({ c2: e.target.value });
                                                                        }}>
                                                                            <option value={0}>0.0</option>
                                                                            <option value={0.5}>0.5</option>
                                                                            <option value={1}>1.0</option>
                                                                            <option value={1.5}>1.5</option>
                                                                            <option value={2}>2.0</option>
                                                                            <option value={2.5}>2.5</option>
                                                                            <option value={3}>3.0</option>
                                                                            <option value={3.5}>3.5</option>
                                                                            <option value={4}>4.0</option>
                                                                            <option value={4.5}>4.5</option>
                                                                            <option value={5}>5.0</option>

                                                                        </select>
                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C3</th>
                                                                    <th>Pertinência e riqueza de argumentos.</th>
                                                                    <th>5.0</th>
                                                                    <th>
                                                                        <select value={this.state.c3} className="custom-select" id="cameras" onChange={(e) => {
                                                                            this.setState({ c3: e.target.value });
                                                                        }}>
                                                                            <option value={0}>0.0</option>
                                                                            <option value={0.5}>0.5</option>
                                                                            <option value={1}>1.0</option>
                                                                            <option value={1.5}>1.5</option>
                                                                            <option value={2}>2.0</option>
                                                                            <option value={2.5}>2.5</option>
                                                                            <option value={3}>3.0</option>
                                                                            <option value={3.5}>3.5</option>
                                                                            <option value={4}>4.0</option>
                                                                            <option value={4.5}>4.5</option>
                                                                            <option value={5}>5.0</option>

                                                                        </select>
                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C4</th>
                                                                    <th>Relação lógica entre as ideias, objetividade, ordenação e clareza das ideias.</th>
                                                                    <th>5.0</th>
                                                                    <th>
                                                                        <select value={this.state.c4} className="custom-select" id="cameras" onChange={(e) => {
                                                                            this.setState({ c4: e.target.value });
                                                                        }}>
                                                                            <option value={0}>0.0</option>
                                                                            <option value={0.5}>0.5</option>
                                                                            <option value={1}>1.0</option>
                                                                            <option value={1.5}>1.5</option>
                                                                            <option value={2}>2.0</option>
                                                                            <option value={2.5}>2.5</option>
                                                                            <option value={3}>3.0</option>
                                                                            <option value={3.5}>3.5</option>
                                                                            <option value={4}>4.0</option>
                                                                            <option value={4.5}>4.5</option>
                                                                            <option value={5}>5.0</option>

                                                                        </select>
                                                                    </th>
                                                                </tr>
                                                                <tr style={{ textAlign: 'center' }}>
                                                                    <th>C5</th>
                                                                    <th>Expressão (domínio da norma culta da Língua Portuguesa e das estruturas da Língua)</th>
                                                                    <th>5.0</th>
                                                                    <th>
                                                                        <select value={this.state.c5} className="custom-select" id="cameras" onChange={(e) => {
                                                                            this.setState({ c5: e.target.value });
                                                                        }}>
                                                                            <option value={0}>0.0</option>
                                                                            <option value={0.5}>0.5</option>
                                                                            <option value={1}>1.0</option>
                                                                            <option value={1.5}>1.5</option>
                                                                            <option value={2}>2.0</option>
                                                                            <option value={2.5}>2.5</option>
                                                                            <option value={3}>3.0</option>
                                                                            <option value={3.5}>3.5</option>
                                                                            <option value={4}>4.0</option>
                                                                            <option value={4.5}>4.5</option>
                                                                            <option value={5}>5.0</option>

                                                                        </select>
                                                                    </th>
                                                                </tr>


                                                            </tbody>
                                                        </table>
                                                        <div className="row">
                                                            <div className="col-4" >
                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" onChange={(e) => {
                                                                        this.setState({ nota_zero: e.target.checked });
                                                                    }} type="checkbox" checked={this.state.nota_zero} />
                                                                    <label className="form-check-label" style={{
                                                                        fontWeight: 'normal',
                                                                        color: 'black',
                                                                        fontSize: '13px'
                                                                    }}>NOTA ZERO</label>
                                                                </div>
                                                            </div>
                                                            <div className="col-4" >

                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" onChange={(e) => {
                                                                        this.setState({ fuga_tema: e.target.checked });
                                                                    }} type="checkbox" checked={this.state.fuga_tema} />
                                                                    <label className="form-check-label" style={{
                                                                        fontWeight: 'normal',
                                                                        color: 'black',
                                                                        fontSize: '13px'
                                                                    }}>Fuga ao tema ou modalidade de texto</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-4" >

                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" onChange={(e) => {
                                                                        this.setState({ texto_nao_articulado: e.target.checked });
                                                                    }} type="checkbox" checked={this.state.texto_nao_articulado} />
                                                                    <label className="form-check-label" style={{
                                                                        fontWeight: 'normal',
                                                                        color: 'black',
                                                                        fontSize: '13px'
                                                                    }}>Texto não articulado</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-4" >

                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" onChange={(e) => {
                                                                        this.setState({ identificacao_candidato: e.target.checked });
                                                                    }} type="checkbox" checked={this.state.identificacao_candidato} />
                                                                    <label className="form-check-label" style={{
                                                                        fontWeight: 'normal',
                                                                        color: 'black',
                                                                        fontSize: '13px'
                                                                    }}>Identificação do candidato</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-4" >

                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" onChange={(e) => {
                                                                        this.setState({ em_branco: e.target.checked });
                                                                    }} type="checkbox" checked={this.state.em_branco} />
                                                                    <label className="form-check-label" style={{
                                                                        fontWeight: 'normal',
                                                                        color: 'black',
                                                                        fontSize: '13px'
                                                                    }} >Em branco</label>
                                                                </div>
                                                            </div>

                                                            <div className="col-4" >

                                                                <div className="form-check form-check-inline">
                                                                    <input className="form-check-input" onChange={(e) => {
                                                                        this.setState({ texto_insuficiente: e.target.checked });
                                                                    }} type="checkbox" checked={this.state.texto_insuficiente} />
                                                                    <label className="form-check-label" style={{
                                                                        fontWeight: 'normal',
                                                                        color: 'black',
                                                                        fontSize: '13px'
                                                                    }}>Texto insuficiente (inferior a 10 linhas)</label>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div> */}
                                                <div className="row" style={{ marginTop: '2rem' }}>
                                                    <div className="col-12" style={{ marginBottom: '2rem' }}>
                                                        <p style={{ color: 'black', fontSize: '1rem', fontWeight: 'bold' }}>Nota final: <span style={{ fontWeight: 'normal' }}>{this.state.criterios_notas_zeradas.filter((item, id) => item.value == true).length > 0 ? '0' : (parseFloat(this.state.c1) + parseFloat(this.state.c2) + parseFloat(this.state.c3)+ parseFloat(this.state.c4) + parseFloat(this.state.c5))}</span></p>
                                                        <div >

                                                        </div>

                                                    </div>

                                                </div>
                                                <div className="row" >
                                                    <div className="col-12" style={{ marginBottom: '2rem' }}>
                                                        <p style={{ color: 'black', fontSize: '1rem', fontWeight: 'bold' }}>Comentário</p>
                                                        <div style={{ whiteSpace: 'pre-line' }}>
                                                            {this.state.comentario == null || this.state.comentario == '' ? '-' : this.state.comentario}
                                                        </div>

                                                    </div>

                                                </div>


                                            </div>

                                        </div>


                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>}
                {this.state.loading_screen == true && <div className="row"> <div className="col-12 d-flex justify-content-center align-items-center" style={{ marginTop: '2rem' }}>
                    <div className="spinner-border text-primary" role="status"  >
                        <span className="sr-only">Loading...</span>
                    </div>
                </div></div>}
                <SweetAlert
                    warning
                    title={"Você tem certeza que deseja finalizar esta correção?"}
                    onConfirm={() => {
                        this.setState({ loading_save: true, show_warning_finish: false });
                        this.finalizar_correcao();
                    }}
                    onCancel={() => this.setState({ show_warning_finish: false })}
                    show={this.state.show_warning_finish}
                    confirmBtnText='Finalizar'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="info"
                    cancelBtnBsStyle="danger"
                    showCancel={true}
                >
                    Ao finalizar você não poderá realizar mais nenhuma alteração nesta correção
                </SweetAlert>
                <SweetAlert
                    danger
                    title={"Erro!"}
                    onConfirm={() => this.setState({ show_error: false })}
                    // onCancel={this.onCancel.bind(this)}
                    show={this.state.show_error}
                    confirmBtnText='Ok'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="info"
                    // cancelBtnBsStyle="success"
                    showCancel={false}
                >
                    {this.state.msg_erro}

                </SweetAlert>
                <SweetAlert
                    success
                    title={"Correção Finalizada!"}
                    onConfirm={() => this.setState({ show_success: false })}
                    // onCancel={this.onCancel.bind(this)}
                    show={this.state.show_success}
                    confirmBtnText='Ok'
                    cancelBtnText='Cancelar'
                    confirmBtnBsStyle="success"
                    // cancelBtnBsStyle="success"
                    showCancel={false}
                >
                    A correção foi finalizada com sucesso. A próxima redação já está nesta página para correção.
                </SweetAlert>
            </div>


        );
    }


}

const mapsStateToProps = (state) => (
    {
        token: state.AppReducer.token,
        faculdade: state.AppReducer.faculdade,
        processo: state.AppReducer.processo,
        user: state.AppReducer.user,
        socket: state.AppReducer.socket,
    }
);

export default connect(mapsStateToProps, { mudarUser, logout, mudarProcesso, mudarSocket })(RedacaoCorrigida);
